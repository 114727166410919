.grid_container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	padding: 50px 9vw;
	gap: 20px;
}

@media (max-width: 480px) {
	.grid_container {
		grid-template-columns: 1fr;
	}
}

@media (min-width: 481px) and (max-width: 768px) {
	.grid_container {
		grid-template-columns: 1fr 1fr;
	}
}

@media (min-width: 769px) and (max-width: 1024px) {
	.grid_container {
		grid-template-columns: 1fr 1fr;
	}
}

@media (min-width: 1025px) and (max-width: 1200px) {
}
