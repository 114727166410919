.first_content_container {
  padding: 0 9vw;
  height: fit-content;
  display: flex;
  align-items: flex-end;
  display: grid;
  grid-template-columns: 60% 1fr;
  gap: 20px;
  margin-top: 50px;
  margin-bottom: 30px;
}

.image_blog_container {
  width: 100%;
  height: 100%;
  /* aspect-ratio: 16/12 !important; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.first_content {
  height: 60%;
  width: 70%;
  background-color: #d95858;
  position: relative;
}

.first_content_image {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -40%);
  height: 480px;
  width: 690px;
  background-size: cover;
  background-position: center;
  z-index: 10;
}

.ads_blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: 10px;
}

.ads_blog > p {
  font-size: 10px;
  position: absolute;
  top: -18px;
}

.ads_section {
  padding: 0px 9vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ads_section > p {
  font-size: 10px;
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translateX(-50%);
}

/* .ads_section > p {
  grid-column: 1/3;
} */

.ads_section_1 {
  width: 100%;
  aspect-ratio: 9/5;
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  color: white;
}

.ads_section_1 p,
.ads_first p {
  z-index: 10;
  text-transform: capitalize;
  font-size: 1rem;
  color: white;
}

.ads_first a,
.ads_section_1 a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.background_gradient_for_ads {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 212, 255, 0) 100%
  );
}
.ads_first {
  /* flex-grow: 1; */
  width: 100%;
  aspect-ratio: 9/5 !important;
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  color: white;
}

.first_content_border {
  position: absolute;
  height: 300px;
  width: 300px;
  border: 4px solid #d95858;
  z-index: -10 !important;
  right: -380px;
  top: -230px;
}

.first_text_content {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10%;
}

.first_text_content h2 {
  font-size: 30px;
  text-align: center;
  color: white;
  margin-bottom: 50px;
}

.btn_container {
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  height: 50px;
}

.btn_container button {
  background: transparent;
  border: none;
  color: white;
  font-size: 22px;
  z-index: 10;
  flex-grow: 0;
  width: fit-content;
}

.circle {
  position: absolute;
  height: 50px;
  width: 50px;
  border: 2px solid #f7d08a;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  transform: translateY(-50%) translateX(-50%);
  transition: 0.5s;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle svg {
  display: none;
  transition: 0.5s;
}

.btn_container:hover .circle {
  left: calc(100% + 30px);
  background: #f7d08a;
}

._hero .btn_container:hover .circle {
  left: calc(100% + 30px);
  background: #d95858 !important;
}

.btn_container:hover .circle svg {
  display: block;
}

/**************** Second content container ****************/

.second_content_container {
  position: relative;
  width: 100%;
}

.second_first_content {
  height: 336px;
  width: 100%;
  background: white;
  padding-top: 100px;
  padding-left: 3vw;
  padding: 170px 3vw 0px 9vw;
}

.second_first_content h1 {
  color: black;
  line-height: 50px;
  width: 45%;
}

.second_first_content p {
  width: 50%;
  color: white;
  line-height: 35px;
  font-size: 18px;
  letter-spacing: 0.56px;
}

.second_second_content {
  height: 336px;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 50px 3vw 0px 9vw;
}

.second_second_content p {
  width: 45%;
  color: black;
  line-height: 35px;
  font-size: 18px;
  letter-spacing: 0.56px;
  margin-bottom: 20px;
}

.second_second_content div {
  /* width: 40%; */
  display: flex;
  justify-content: center;
}
.second_second_content button {
  color: black;
}

/* .second_second_content button {
	padding: 7px 15px;
	border: 2px solid #d95858;
	background: transparent;
	border-radius: 10px;
	font-size: 18px;
	transition: 0.5s;
	cursor: pointer;
	font-weight: 500;
	color: black;
} */

/* .second_second_content button:hover {
	color: white;
	background: #d95858;
} */

.absolute_part {
  height: 446px;
  position: absolute;
  top: 50%;
  background-image: url("https://source.unsplash.com/random/?trees");
  background-size: cover;
  background-position: center;
  width: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 10;
}

.second_border_1 {
  height: 436px;
  width: 40vw;
  position: absolute;
  top: 50%;
  right: -100px;
  border: 2px solid black;
  transform: translateY(-55%);
}

.second_border_2 {
  height: 436px;
  width: 40vw;
  position: absolute;
  top: 50%;
  right: -250px;
  border: 2px solid black;
  transform: translateY(-60%);
}

/* HOMEPAGE DISCOVER - 2 START */
.ds2_container {
  display: grid;
  grid-template-columns: 0.81fr 1fr;
  padding-left: 9vw;
  height: fit-content;
  padding-top: 70px;
  padding-bottom: 40px;
}

.ds2_text_container {
  padding-right: 30px;
}

.ds2_text_container p {
  color: black;
  line-height: 35px;
  font-size: 18px;
  letter-spacing: 0.56px;
  padding-top: 20px;
}

.ds2_text_container button {
  color: black;
}

.ds2_text_container .btn_container {
  margin-top: 20px;
}
.ds2_image_container {
  position: relative;
}

.ds2_image {
  height: 446px;
}

.ds2_ab1 {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -10;
  border: 2px solid black;
  top: -20px;
  left: 30%;
}
.ds2_ab2 {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -10;
  border: 2px solid black;
  top: -40px;
  left: 60%;
}

/* HOMEPAGE DISCOVER - 2 ENDS */

@media (max-width: 480px) {
  /* HOMEPAGE DISCOVER - 2 START */

  .ds2_container {
    grid-template-columns: 1fr;
    padding-left: 3vw;
  }

  .ads_section {
    padding: 0px 1rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .ads_section > p {
    grid-column: 1/2;
  }

  .ds2_text_container {
    grid-row: 2/3;
    padding-top: 20px;
    padding-right: 3vw;
  }

  .ds2_text_container p {
    font-size: 16px;
  }
  /* HOMEPAGE DISCOVER - 2 ENDS */

  .first_content_container {
    padding: 0 1rem;
    grid-template-columns: 1fr;
  }

  .image_blog_container {
    aspect-ratio: 16/18 !important;
  }

  .ads_first {
    aspect-ratio: 16/9;
  }

  .first_content {
    height: 50%;
    width: 90%;
  }

  .first_content_image {
    right: 0;
    top: -30px;
    transform: translate(15%, -80%);
    height: 270px;
    width: 80%;
    background-image: url("https://source.unsplash.com/random/?mountains?sky");
    background-size: cover;
    background-position: center;
    z-index: 10;
  }

  .first_content_border {
    height: 200px;
    width: 200px;
    z-index: -10 !important;
    right: 80px;
    top: -290px;
  }

  .first_text_content {
    height: 100%;
    width: 100%;
  }

  .second_first_content {
    height: fit-content;
    padding: 100px 3vw 250px 9vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid blue;
  }

  .second_first_content h1 {
    line-height: 35px;
    width: 100%;
  }
  .second_first_content p {
    width: 100%;
  }

  .second_second_content {
    height: fit-content;
    padding: 400px 3vw 0px 9vw;
  }

  .second_second_content h1 {
    line-height: 35px;
    width: 100%;
  }

  .second_second_content p {
    width: 100%;
    line-height: 35px;
    font-size: 18px;
    letter-spacing: 0.56px;
    margin-bottom: 20px;
  }

  .absolute_part {
    height: 550px;
    position: absolute;
    top: 43%;
    width: 80vw;
    transform: translateY(-50%);
  }

  .second_border_1 {
    height: 436px;
    width: 80vw;
    position: absolute;
    top: 53%;
    left: 40px;
    border: 2px solid black;
    transform: translateY(-55%);
  }

  .second_second_content div {
    width: 100%;
  }

  .second_second_content button {
    margin-top: 10px;
  }

  .second_second_content .btn_container {
    width: fit-content;
    height: fit-content;
  }

  .second_second_content .btn_container button {
    margin-top: 0px;
  }

  .second_second_content .circle {
    height: 50px;
    width: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .first_content_container {
    padding: 0 3vw;
    height: 65vh;
  }

  .first_content {
    height: 50%;
    width: 90%;
  }

  .first_content_image {
    right: -20px;
    top: -30px;
    transform: translate(15%, -80%);
    height: 270px;
    width: 50%;
    background-image: url("https://source.unsplash.com/random/?mountains?sky");
    background-size: cover;
    background-position: center;
    z-index: 10;
  }

  .first_content_border {
    height: 200px;
    width: 200px;
    z-index: -10 !important;
    right: 80px;
    top: -290px;
  }

  .first_text_content {
    height: 100%;
    width: 100%;
  }

  .second_first_content {
    height: fit-content;
    padding: 100px 3vw 250px 9vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .second_first_content h1 {
    line-height: 35px;
    width: 100%;
  }
  .second_first_content p {
    width: 100%;
  }

  .second_second_content {
    height: fit-content;
    padding: 400px 3vw 0px 9vw;
  }

  .second_second_content h1 {
    line-height: 35px;
    width: 100%;
  }

  .second_second_content p {
    width: 100%;
    line-height: 35px;
    font-size: 18px;
    letter-spacing: 0.56px;
    margin-bottom: 20px;
  }

  .absolute_part {
    height: 550px;
    position: absolute;
    top: 43%;
    width: 80vw;
    transform: translateY(-50%);
  }

  .second_border_1 {
    height: 436px;
    width: 80vw;
    position: absolute;
    top: 55.5%;
    left: 100px;
    border: 2px solid black;
    transform: translateY(-55%);
  }

  .second_second_content div {
    width: 100%;
  }

  .second_second_content button {
    margin-top: 10px;
  }

  .second_second_content .btn_container {
    width: fit-content;
    height: fit-content;
  }

  .second_second_content .btn_container button {
    margin-top: 0px;
  }

  .second_second_content .circle {
    height: 50px;
    width: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
