.slider_container {
  padding: 10px 0 10px 9vw !important;
}

.slider_container h2 {
  padding: 0 0 0px 0vw;
}

.heading_and_btn {
  display: flex;
  justify-content: space-between;
  padding-right: 9vw;
  align-items: center;
  padding-bottom: 10px;
}

.heading_and_btn button {
  height: 40px;
  font-size: 1rem;
  color: #023c40;
  text-decoration: underline;
  padding: 5px 15px;
  border: none;
  /* background: #023c40; */
  background: none;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 480px) {
  .slider_container h2 {
    font-size: 22px !important;
  }

  .heading_and_btn {
    padding-right: 0;
  }

  .heading_and_btn button {
    font-size: 0.8rem !important;
  }

  .slider_container {
    padding: 10px 1rem 10px 1rem !important;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .slider_container h2 {
    padding: 0 0 30px 3vw;
  }
}
