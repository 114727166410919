@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

h1,
h2,
h3 {
  font-family: "Poppins";
}

/* Customization for Splides */

.splide__pagination.splide__pagination--ltr {
  transform: translateY(40px);
}

.splide__pagination.splide__pagination--ltr button {
  background: transparent;
  border: 1px solid #023c40;
}

.splide__pagination.splide__pagination--ltr button.is-active {
  background: #023c40;
  border: 1px solid #023c40;
}

#home_featured .splide__pagination.splide__pagination--ltr button {
  background: transparent;
  border: 1px solid white;
}

#home_featured .splide__pagination.splide__pagination--ltr button.is-active {
  background: white;
  border: 1px solid white;
}

.splide__arrow.splide__arrow--prev {
  border-radius: 0;
  left: 0;
  padding: 10px;
  height: calc(2rem + 20px);
  width: calc(2rem + 20px);
}

.splide__arrow.splide__arrow--next {
  border-radius: 0;
  right: 0;
  padding: 10px;
  height: calc(2rem + 20px);
  width: calc(2rem + 20px);
}

._hero #circle {
  border-color: #d95858 !important;
}

._hero #discover_container:hover #circle {
  background-color: #d95858;
}

.loader_search {
  width: 25px;
  height: 25px;
  border: 2px solid #d95858;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ql-align-justify {
  text-align: justify;
}

.ql-align-left {
  text-align: left;
}

.ql-align-right {
  text-align: right;
}

.ql-align-center {
  text-align: center;
}

.ql-lineHeight-1 {
  line-height: 1 !important;
}

.ql-lineHeight-1_5 {
  line-height: 1.5 !important;
}

.ql-lineHeight-2 {
  line-height: 2 !important;
}

.ql-lineHeight-2_5 {
  line-height: 2.5 !important;
}

.ql-lineHeight-3 {
  line-height: 3 !important;
}

.ql-lineHeight-3_5 {
  line-height: 3.5 !important;
}
