.nav_container {
  height: 70px;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  background: rgba(255, 253, 249, 0.5);
  padding: 0 9vw;
  display: flex;
  align-items: center;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  font-family: "Poppins";
  font-size: 20px;
}

.logo {
  height: 60px;
}

.menu {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  bottom: calc(100vh - 70px);
  width: 100%;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
}

.small_menu {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  bottom: calc(100vh - 70px);
  width: 100%;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
}

.small_menu_open {
  height: calc(100vh - 70px);
  margin-left: auto;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.4);
}

.small_menu_content {
  background: white;
  margin-left: auto;
  width: 60%;
  height: 100%;
}

.menu_open {
  height: calc(100vh - 70px);
  margin-left: auto;
  bottom: 0;
  z-index: 100;
  background: white;
}

.right_nav_elements {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  display: flex;
  gap: 40px;
}

.right_nav_elements li {
  cursor: pointer;
  font-size: 1rem;
}

/************************ FOOTER - START **************/
.footer_container {
  height: fit-content;
  padding: 50px 9vw;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  border-top: 1px solid black;
}

.footer_s_link {
  display: flex;
  align-items: center;
  gap: 5px;
}

.footer_s_link svg {
  height: 18px;
  width: 18px;
}

.menu_btn {
  display: none;
  width: 27px;
  height: 20px;
  flex-direction: column;
  justify-content: space-between;
}

.one,
.two,
.three {
  height: 2px;
  width: 100%;
  background: rgb(54, 54, 54);
  border-radius: 100px;
  flex-shrink: 0;
  transition: 0.5s;
  transform-origin: 100% 50%;
}

.one_move {
  transform: rotateZ(-45deg);
}

.two_move {
  transform: rotateY(90deg);
  transform-origin: right;
}

.three_move {
  transform: rotateZ(45deg);
  transform-origin: right;
}

.right_nav_elements_mob {
  display: none;
  height: fit-content;
  top: 0%;
  flex-direction: column;
  gap: 15px;
  list-style: none;
  font-size: 20px;
  font-family: "Poppins";
  text-align: end;
  padding-inline: 20px;
  padding-top: 20px;
}

.right_nav_elements_mob li {
  border-bottom: 1px solid black;
}

.social {
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 10px;
}

.blog_list,
.support_list {
  list-style: none;
  font-weight: 500;
  line-height: 40px;
}

.blog_list li,
.support_list li {
  cursor: pointer;
}

.social svg {
  height: 25px;
  width: 25px;
  fill: rgba(2, 60, 64);
}

/* .social:hover {
	background: rgba(2, 60, 64);
}
.social:hover svg {
	fill: white !important;
} */

.f_blog_container {
  width: 100%;
  height: 100%;
  grid-column: 5/7;
}

.f_support_container {
  width: 100%;
  height: 100%;
  grid-column: 8/10;
}

.logo_txt_container {
  width: 100%;
  height: 100%;
  grid-column: 1/5;
}

.social__ {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  grid-column: 10/13;
}

.social__ p {
  text-align: end;
  font-weight: 500;
  font-size: 16px;
}

.social_container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-end;
}

.cw_1 {
  display: block;
}

.cw_2 {
  display: none;
}

/************************ FOOTER - END   **************/

.search_container_main {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%); */
  margin-left: 20px;
}

.search_container {
  height: 35px;
  width: 300px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  margin-right: 20px;
  border-radius: 5px;
  /* overflow: hidden; */
  display: flex;
  position: relative;

  /* box-shadow: rgba(255, 255, 255, 0.25) 0px 5px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
}

.search_container input {
  height: 100%;
  border: none;
  background: transparent;
  padding: 10px;
  color: rgb(40, 40, 40);
  flex-grow: 1;
}

.search_container input:focus {
  outline: none;
  background: white;
  border-radius: 5px 0 0 5px;
}

.search_container svg {
  height: 100%;
  padding: 6px;
  flex-shrink: 0;
  cursor: pointer;
}

.search_container path {
  stroke: rgba(0, 0, 0, 0.5);
}

.search_results_container {
  position: absolute;
  width: 100%;
  height: fit-content;
  background: rgba(255, 253, 249, 0.5);
  z-index: 100;
  top: 106%;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}

.search_results_container ul {
  list-style: none;
  width: 100%;
  max-height: 300px;
  overflow: auto;
}

.search_results_container ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  padding: 5px 10px;
  background: white;
  transition: 0.5;
  font-size: 14px;
  cursor: pointer;
}

.search_results_container ul li:hover {
  background: rgb(212, 212, 212);
}

.search_results_container div {
  width: 100%;
  /* text-decoration: underline; */
  background: rgba(255, 253, 249, 0.3);
  padding: 7px;
  text-align: center;
}

.mob_search_icon {
  display: none;
}

.mob_modal {
  width: 100%;
  height: 100%;
  padding: 20px 9vw 100px 9vw;
  position: relative;
}

.mob_modal_input {
  width: 100%;
  height: 50px;
  background: transparent;
  border: 1px solid grey;
  /* border: none; */
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.mob_modal_input input {
  height: 100%;
  border: none;
  background: transparent;
  width: 100%;
  flex-grow: 1;
  padding: 5px 10px;
  font-size: 1.1rem;
}

.mob_modal_input svg {
  height: 27px;
  flex-shrink: 0;
}

.mob_modal_input input:focus {
  outline: none;
}

.mob_modal ul {
  list-style: none;
  height: 100%;
  padding-top: 10px;
  overflow: auto;
}

.mob_modal li {
  padding: 15px 5px;
  font-size: 1.4rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: 400;
  cursor: pointer;
}

.mob_modal li:hover {
  background: rgba(0, 0, 0, 0.07);
}

@media (max-width: 480px) {
  .logo {
    height: 40px;
  }
  .cw_1 {
    display: none;
  }

  .cw_2 {
    display: block;
  }

  .social {
    height: 35px;
    width: fit-content;
  }

  .social svg {
    height: 15px;
    width: 15px;
    fill: rgba(2, 60, 64);
  }

  .right_nav_elements {
    display: none;
  }

  .menu_btn {
    display: inline-flex !important;
  }

  .right_nav_elements_mob {
    display: flex;
  }

  .social_container {
  }

  .footer_container {
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    padding: 50px 3vw;
  }

  .f_blog_container,
  .f_support_container {
    grid-column: 1/3;
  }

  .logo_txt_container {
    grid-column: 1/2;
  }

  .social__ {
    grid-column: 1/3;
    grid-row: 5/6;
  }

  .social {
    align-items: center;
  }

  .social > h5 {
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
  }

  .social > p {
    display: block;
    font-size: 10px;
    white-space: nowrap;
    text-align: center;
  }

  .social_container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .blog_list,
  .support_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .blog_list li,
  .support_list li {
    white-space: nowrap;
    padding-right: 20px;
    cursor: pointer;
  }

  .copyright_text {
    display: block;
    font-weight: 500;
    font-size: 16px;
  }

  .search_container {
    display: none;
  }

  .mob_search_icon {
    display: inline-block;
    height: 27px;
    margin-right: 20px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .cw_1 {
    display: none;
  }

  .cw_2 {
    display: block;
  }

  .social {
    height: 35px;
    width: fit-content;
  }

  .social svg {
    height: 15px;
    width: 15px;
    fill: rgba(2, 60, 64);
  }

  .right_nav_elements {
    display: none;
  }

  .menu_btn {
    display: inline-flex !important;
  }

  .right_nav_elements_mob {
    display: flex;
  }

  .social_container {
  }

  .footer_container {
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    padding: 50px 3vw;
  }

  .f_blog_container,
  .f_support_container {
    grid-column: 1/3;
  }

  .logo_txt_container {
    grid-column: 1/2;
  }

  .social__ {
    grid-column: 1/3;
    grid-row: 5/6;
  }

  .social {
    align-items: center;
  }

  .social > h5 {
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
  }

  .social > p {
    display: block;
    font-size: 10px;
    white-space: nowrap;
    text-align: center;
  }

  .social_container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .blog_list,
  .support_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .blog_list li,
  .support_list li {
    white-space: nowrap;
    padding-right: 20px;
    cursor: pointer;
  }

  .copyright_text {
    display: block;
    font-weight: 500;
    font-size: 16px;
  }

  .search_container {
    display: none;
  }

  .mob_search_icon {
    display: inline-block;
    height: 27px;
    margin-right: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .right_nav_elements {
    gap: 20px;
  }
  .search_container {
    width: 250px;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .right_nav_elements {
    gap: 20px;
  }
}
