.container {
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 29px;
  padding-top: 70px;
  color: white;
  background-size: cover !important;
  background-position: center !important;
}

.container h1 {
  text-align: center;
  font-family: "Poppins";
  font-size: 2.5rem;
}

.container p {
  font-size: 20px;
  line-height: 44px;
  letter-spacing: 0.56px;
  width: 60%;
  text-align: center;
}

.hero_button {
  padding: 7px 15px;
  border: 2px solid #d95858;
  background: transparent;
  border-radius: 10px;
  font-size: 18px;
  transition: 0.5s;
  cursor: pointer;
  font-weight: 500;
  color: white;
}

.hero_button:hover {
  background-color: #d95858;
  color: white;
}

@media (max-width: 480px) {
  .container {
    padding: 70px 0 0 0;
  }
  .container p {
    width: 95%;
    font-size: 18px;
    line-height: 30px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .container {
    padding: 70px 0 0 0;
  }
  .container p {
    width: 95%;
    font-size: 18px;
    line-height: 30px;
  }
}
